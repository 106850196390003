<template>
  <main
    class="blank-aside-js page_bg_grey"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <p class="main-title weight-700">
      {{ $t("Profit commissions bill payments") }}
    </p>

    <section class="filter-part sm-content">
      <div class="form-item">
        <label>{{ $t('Category') }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('Everything')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('Card Type') }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('Everything')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('Supplier ID') }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('Everything')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('Product') }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('Everything')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
    </section>

    <section class="sm-content mt-5">
      <label>{{ $t('Tags') }}</label>
      <div class="tag-block">
        <img src="/assets/img/icons/search-light.svg" alt="" class="search-icon" />
        <div class="tag-close_btn total-tag-close">
          <img src="/assets/img/icons/times-light.svg" alt="" />
        </div>
        <div class="tag-item tag-item-active">
          {{ $t('Tags') }}
          <div class="tag-close_btn">
            <img src="/assets/img/icons/times-light.svg" alt="" />
          </div>
        </div>
        <div class="tag-item tag-item-active">
          {{ $t('Tags') }}
          <div class="tag-close_btn">
            <img src="/assets/img/icons/times-light.svg" alt="" />
          </div>
        </div>
      </div>
    </section>

    <section class="sm-content mt-15">
      <div class="d-flex align-items-end bottom-setting">
        <div class="detail-form">
          <label>{{ $t("Customer Type") }}</label>
          <div class="mt-2">
            <input
              type="text"
              class="input"
              :placeholder="$t('Everything')"
            />
          </div>
        </div>
      </div>
      <div class="d-flex align-items-end bottom-setting mt-15">
        <div class="detail-form mb-7">
          <label>{{ $t("Commission for marketer/distributor") }}</label>
          <div class="mt-2">
            <input
              type="number"
              class="input"
              value="10"
            />
          </div>
        </div>
        <button class="primary-btn mb-7">
          {{ $t("Apply profit commission to all resellers") }}
        </button>
        <button class="primary-btn bg-red3 mb-7">
          {{ $t("Remove profit commission on all distributors") }}
        </button>
      </div>
      <div class="d-flex align-items-end bottom-setting mt-7">
        <div class="detail-form mb-7">
          <label>{{ $t("Consumer fee") }}</label>
          <div class="mt-2">
            <input
              type="number"
              class="input"
              value="2"
            />
          </div>
        </div>
        <button class="primary-btn mb-7">
          {{ $t("Apply consumer commission to all resellers") }}
        </button>
        <button class="primary-btn bg-red3 mb-7">
          {{ $t("Remove consumer commission on all distributors") }}
        </button>
      </div>
      <div class="d-flex align-items-center timing-vat">
        <v-checkbox
          color="#0D3856"
          class="cus-checkbox"
          v-model="isCommissionLevel"
        ></v-checkbox>
        <label class="px-5 color-primary cancel-label">{{
          $t('Commission level')
        }}</label>
      </div>
      <div class="mt-7" v-if="isCommissionLevel">
        <label class="space-nowrap d-block">{{ $t("Level of consumer commission") }}</label>
        <input
          type="text"
          class="input px-5 commission-level"
          value="5"
          @keypress="isNumber($event)"
        />
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'SCCommissions',
  data() {
    return {
      isCommissionLevel: false,
    }
  },
  computed: {
  }
};
</script>

<style lang="scss" scoped>
.filter-part {
  display: flex;
  justify-content: space-between;
  .form-item {
    width: calc(25% - 30px);
  }
}
.detail-form {
  width: 100%;
  max-width: 250px;
}
.bottom-setting {
  .primary-btn {
    width: unset;
    padding: 0 20px;
    margin-right: 30px;
    min-width: 350px;
    font-size: 16px;
  }
}
.commission-level {
  max-width: 250px;
}

@media screen and (max-width: 980px) {
  .filter-part {
    flex-wrap: wrap;
    .form-item {
      width: 100%;
    }
  }
  .bottom-setting {
    flex-wrap: wrap;
    .primary-btn {
      width: 100%;
      margin-right: 0;
    }
  }
  .detail-form {
    max-width: unset;
  }
}

.ltr-type {
  .bottom-setting .primary-btn {
    margin-right: 0;
    margin-left: 30px;
  }
}
</style>